<template>
  <div>
    <h1 class="font-weight-light mb-2 headline">
      {{ atelier.nom }}
      <v-btn
        class="boutonAllerAtelier"
        color="error"
        @click="onRetournerConference()"
      >
        Retour à la conférence - {{ atelier.conference.nom }}
      </v-btn>
    </h1>
    <v-container
      style="margin-top:25px!important;"
      fluid
      class="atelier-cam-container grey ma-0 pa-0"
      data-cy="pageSalleAtelier"
    >
      <div
        ref="videoContainer"
        class="atelier-video-container"
      />
    </v-container>
    <v-container>
      <!-- VIDEO -->
      <v-btn
        :class="partageCamera ? 'success mx-1' : 'grey mx-1'"
        icon
        @click="toggleCamera()"
      >
        <v-icon>mdi-video-outline</v-icon>
      </v-btn>

      <!-- AUDIO -->
      <v-btn
        :class="partageMicro ? 'success mx-1' : 'grey mx-1'"
        icon
        @click="toggleMicro()"
      >
        <v-icon>mdi-microphone</v-icon>
      </v-btn>

      <!-- PARTAGE ÉCRAN -->
      <v-btn
        :class="partageÉcran ? 'success mx-1' : 'grey mx-1'"
        icon
        @click="toggleEcran()"
      >
        <v-icon>mdi-monitor-screenshot</v-icon>
      </v-btn>
    </v-container>
    <div>
      <user-video :stream-manager="publisher" />
    </div>
  </div>
</template>

<script>
  import UserVideo from '../composantes/UserVideo'
  import { OpenVidu } from 'openvidu-browser'
  import restApiService from '@/services/restApiService.js'
  import Vue from 'vue'
  export default {
    components: {
      UserVideo,
    },
    data: () => ({
      atelier: null,
      listeVideo: [],
      idInterval: null,

      OV: undefined,
      session: undefined,
      token: undefined,
      publisher: undefined,
      subscribers: [],
      videoElements: {},
      width: 320,
      height: 240,
      partageCamera: false,
      partageMicro: false,
      partageÉcran: false,
      videoSource: undefined,
      muted: false,
    }),

    computed: {
      publishAudio () {
        return this.partageMicro
      },
      publishVideo () {
        return this.partageCamera || this.partageÉcran
      },
      etaitPartageEcran () {
        return this.videoSource === 'screen'
      },
      etaitPartageCamera () {
        return typeof this.videoSource === 'undefined'
      },
    },

    beforeDestroy () {
      setTimeout(() => {
        this.leaveSession()
      })
      clearInterval(this.idInterval)
    },

    created () {
      console.log('OvExposant -> created')
      setTimeout(() => {
        this.initOpenVidu()
        this.initIntervalStyle()
      })
      window.atelierVm = this
      restApiService
        .get('/api/ateliers/' + this.$route.params.idAtelier)
        .then(result => {
          this.atelier = result.data
        })
        .catch(error => alert(error))
    },

    methods: {
      initIntervalStyle () {
        this.idInterval = setInterval(() => {
          let maxWidth = ''
          let maxHeight = ''
          const keys = Object.keys(this.videoElements)
          console.log('Length', keys.length)
          if (keys.length <= 2) {
            maxWidth = '50%'
            maxHeight = '100%'
          } else if (keys.length <= 3) {
            maxWidth = '33%'
            maxHeight = '100%'
          } else if (keys.length <= 4) {
            maxWidth = '50%'
            maxHeight = '50%'
          }
          keys.forEach(id => {
            const vidEl = this.videoElements[id]
            console.log(vidEl, maxWidth, maxHeight)
            vidEl.style.maxWidth = maxWidth
            vidEl.style.maxHeight = maxHeight
          // vidEl.style.border = '3px solid red'
          })
        }, 1000)
      },
      initOpenVidu () {
        console.log(`
      *****************************
      *
      *  Openvidu new version!!!! 21:14
      *
      *****************************`)
        this.OV = new OpenVidu()
        this.session = this.OV.initSession()
        this.session.on('streamCreated', ({ stream }) => {
          console.log('OvKiosque->streamCreated')
          const subscriber = this.session.subscribe(
            stream,
            this.$refs.videoContainer,
          )
          this.subscribers.push(subscriber)

          subscriber.on('videoElementCreated', event => {
            const vidEl = event.element
            this.videoElements[vidEl.id] = vidEl
            vidEl.autoplay = true
            vidEl.playsinline = true
            // vidEl.style = 'width:320px;'
            vidEl.classList.add('video-item')
            setTimeout(() => {
              vidEl
                .play()
                .then(() => {
                  console.error('Can play media unmuted')
                })
                .catch(() => {
                  console.error("Can't play media unmuted")
                  this.$emit('playblocked', true)
                  vidEl.muted = true
                  this.muted = true
                  vidEl
                    .play()
                    .then(() => console.error('Can play if muted'))
                    .catch(err => console.error('Still fails even muted', err))
                })
            }, 100)
          })

          subscriber.on('videoElementDestroyed', event => {
            const vidEl = event.element
            Vue.delete(this.videoElements, vidEl.id)
            if (this.videoElements.length === 0) {
              this.muted = false
            }
          })
        })

        this.session.on('streamPlaying', ({ stream }) => {
          console.log('OvKiosque->streamPlaying')
        })
        // On every Stream destroyed...
        this.session.on('streamDestroyed', ({ stream }) => {
          console.log('OvKiosque->streamDestroyed')
          const index = this.subscribers.indexOf(stream.streamManager, 0)
          if (index >= 0) {
            this.subscribers.splice(index, 1)
          }
        })

        const context = `ORKA-A-${this.$route.params.idAtelier}`
        console.log('context: ' + context)

        restApiService
          .post('/api/openvidu/api-sessions/get-token', {
            sessionName: context,
            context: context,
          })
          .then(response => {
            this.token = response.data[0]
            console.log('OvKiosque->token' + this.token)
            this.session
              .connect(this.token)
              .then(() => {
                console.log('OvKiosque->connected')
              })
              .catch(error => {
                console.error(
                  'There was an error connecting to the session:',
                  error.code,
                  error.message,
                )
              })
          })
        window.addEventListener('beforeunload', this.leaveSession)
      },

      updateMainVideoStreamManager (sub) {
        console.log(sub)
      },

      unmute () {
        Object.keys(this.videoElements).forEach(key => {
          const el = this.videoElements[key]
          el.muted = false
        })
        this.muted = false
      },

      toggleCamera () {
        console.log('OvKiosque->toggleCamera')

        if (this.partageCamera) {
          this.partageCamera = false
        } else {
          this.partageCamera = true

          if (this.partageÉcran) {
            this.partageÉcran = false
          }
        }

        if (this.publisher && !this.etaitPartageEcran) {
          this.publisher.publishVideo(this.publishVideo)

          if (!this.publishVideo && !this.publishAudio) {
            this.session.unpublish(this.publisher)
            this.publisher = null
          }
        } else {
          this.republish()
        }
      },

      toggleMicro () {
        this.partageMicro = !this.partageMicro
        if (this.publisher) {
          this.publisher.publishAudio(this.publishAudio)

          if (!this.publishVideo && !this.publishAudio) {
            this.session.unpublish(this.publisher)
            this.publisher = null
          }
        } else {
          this.republish()
        }
      },

      toggleEcran (publish) {
        console.log('OvKiosque->toggleEcran')

        if (this.partageÉcran) {
          this.partageÉcran = false
        } else {
          this.partageÉcran = true

          if (this.partageCamera) {
            this.partageCamera = false
          }
        }

        if (this.publisher && !this.etaitPartageCamera) {
          this.publisher.publishVideo(this.publishVideo)
          if (!this.publishVideo && !this.publishAudio) {
            this.session.unpublish(this.publisher)
            this.publisher = null
          }
        } else {
          this.republish()
        }
      },

      leaveSession () {
        console.log('OvKiosque->leaveSession')
        // --- Leave the session by calling 'disconnect' method over the Session object ---
        if (this.session) this.session.disconnect()

        this.session = undefined
        this.publisher = undefined
        this.subscribers = []
        this.OV = undefined

        window.removeEventListener('beforeunload', this.leaveSession)
      },

      urlImageSansVideo () {
        return restApiService.mediaUrl(this.kiosque.mediaImage)
      },

      republish () {
        if (this.publisher) {
          this.session.unpublish(this.publisher)
          this.publisher = null
        }

        setTimeout(() => {
          console.log(
            `Publish video ${this.publishVideo}, publish audio ${
            this.publishAudio
          }, video source: ${
            this.publisher ? this.publisher.videoSource : 'none'
            }`,
          )

          if (this.partageCamera || this.partageMicro || this.partageÉcran) {
            let newVideoSource
            if (this.partageÉcran) {
              newVideoSource = 'screen'
            }

            this.videoSource = newVideoSource

            const publisher = this.OV.initPublisher(
              undefined,
              {
                audioSource: undefined, // The source of audio. If undefined default microphone
                videoSource: newVideoSource, // The source of video. If undefined default webcam
                publishAudio: this.publishAudio, // Whether you want to start publishing with your audio unmuted or not
                publishVideo: this.publishVideo, // Whether you want to start publishing with your video enabled or not
                resolution: `${this.width}x${this.height}`, // The resolution of your video
                frameRate: 30, // The frame rate of your video
                insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                mirror: false, // Whether to mirror your local video or not
              },
              error => {
                // Function to be executed when the method finishes
                if (error) {
                  console.error('Error while initializing publisher: ', error)
                } else {
                  console.log('Publisher successfully initialized')
                }
              },
            )

            this.publisher = publisher
            this.session.publish(this.publisher)
          } else {
            this.publisher = null
          }
        }, 200)
      },
      onRetournerConference () {
        const atelierInfo = {
          id: this.atelier.id,
        }
        restApiService.post('/api/ateliers/fermerAtelierSimple', atelierInfo).then((res) => {
        })
      },
    },
  }
</script>

<style lang="scss">
.atelier-cam-container {
  min-height: 320px;
  margin-top:25px;
}
.atelier-video-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-height: 80vh;
  & > video {
    // flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25vw;
    // max-width: 25%;
  }
}
</style>
<style scoped>
.boutonAllerAtelier{
  float:right;
  margin-left: auto;
}
</style>
